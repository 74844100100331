import React from 'react';
import get from 'lodash.get';

import {
  Spacing,
  Container,
  SharedStyles,
  Fieldset,
  FieldsGroup,
  FieldInput,
  FieldSubmit,
  FieldSelect,
  FieldRadios,
  Loyalty,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionInfo, StTitle } = SharedStyles;

export default function LoyaltyPage({ page }) {
  let { title, subtitle, topTextItems, logo, background } = page;

  return (
    <>
      <Spacing
        style={{
          backgroundImage: `url("${get(background, 'localFile.publicURL')}")`,
        }}
      >
        <Container useLargeWidth>
          <Loyalty
            colorWhite
            logo={get(logo, 'localFile.publicURL')}
            items={topTextItems}
          />
        </Container>
      </Spacing>

      <Spacing>
        <StSectionInfo>
          <Container>
            <StTitle colorBlack styleMedium>
              <h2>{title}</h2>
            </StTitle>
            <p>{get(subtitle, 'subtitle')}</p>
          </Container>
        </StSectionInfo>
      </Spacing>
      <Container useLargeWidth>
        <hr />
      </Container>

      <Spacing>
        <Container useLargeWidth>
          <form>
            <Fieldset legend="Salon / shop name*">
              <FieldSelect
                id="select-id-1"
                label="Please select"
                options={[
                  {
                    label: 'Value 1',
                    value: 'value-1',
                  },
                  {
                    label: 'Value 2',
                    value: 'value-2',
                    selected: true,
                  },
                  {
                    label: 'Value 3',
                    value: 'value-3',
                  },
                  {
                    label: 'Value 3',
                    value: 'value-3',
                  },
                ]}
              />
            </Fieldset>
            <Fieldset legend="Owner / Manager*">
              <FieldInput
                type="text"
                id="name-id-1"
                label="Enter salon name if applicable"
              />
            </Fieldset>
            <FieldsGroup>
              <Fieldset legend="Salon Web Address*">
                <FieldInput type="text" id="name-id-12" />
              </Fieldset>
              <Fieldset legend="Distributor Name*">
                <FieldInput type="text" id="name-id-13" />
              </Fieldset>
            </FieldsGroup>
            <Fieldset legend="Address">
              <FieldsGroup>
                <FieldInput type="text" id="address-id-1" label="Street" />
                <FieldInput type="text" id="address-id-2" label="City" />
              </FieldsGroup>
              <FieldsGroup>
                <FieldInput
                  type="text"
                  id="address-id-3"
                  label="State / Province / Region"
                />
                <FieldInput type="text" id="address-id-4" label="Country" />
              </FieldsGroup>
              <FieldsGroup>
                <FieldInput
                  type="text"
                  id="address-id-4"
                  label="Postal / Zip Code"
                />
              </FieldsGroup>
            </Fieldset>
            <FieldsGroup>
              <Fieldset legend="Phone*">
                <FieldsGroup widthAuto>
                  <FieldInput
                    type="text"
                    id="num-id-1"
                    label="(###)"
                    size={10}
                  />
                  <FieldInput type="text" id="num-id-2" label="###" size={10} />
                  <FieldInput
                    type="text"
                    id="num-id-3"
                    label="####"
                    size={10}
                  />
                </FieldsGroup>
              </Fieldset>
              <Fieldset legend="How many full-time stylists do you employ?*">
                <FieldRadios
                  typeList
                  radioStyle2
                  items={[
                    {
                      label: 'Booth Renter',
                      id: 'radio-id-1',
                      name: 'radio-name',
                    },
                    {
                      label: '1-5',
                      id: 'radio-id-2',
                      name: 'radio-name',
                      checked: true,
                    },
                    {
                      label: '6-10',
                      id: 'radio-id-3',
                      name: 'radio-name',
                    },
                    {
                      label: '11-20',
                      id: 'radio-id-4',
                      name: 'radio-name',
                    },
                    {
                      label: '21+',
                      id: 'radio-id-5',
                      name: 'radio-name',
                    },
                  ]}
                />
              </Fieldset>
            </FieldsGroup>
            <Fieldset>
              <p>
                I certify that all data above is correct. I accept Kenra
                Professional’s terms and conditions. <br /> I allow Kenra
                Professional to contact me via email with updates and
                promotions.
              </p>
            </Fieldset>
            <Fieldset legend="Owner / Manager / Stylist Signature *">
              <FieldInput
                type="text"
                id="name-id-13"
                label="Your information is secure and is not shared with any third parties. Privacy Policy"
              />
            </Fieldset>
            <FieldSubmit>
              <input value="submit" type="submit" />
            </FieldSubmit>
          </form>
        </Container>
      </Spacing>
    </>
  );
}
