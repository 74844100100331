import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Loyalty from 'components/Loyalty';

export default function QuizPage(props) {
  let {
    data: { contentfulLoyalty },
  } = props;

  return (
    <Layout>
      <Loyalty page={contentfulLoyalty} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulLoyalty {
      id
      title
      subtitle {
        subtitle
      }
      topTextItems {
        items {
          text
          title
        }
        title
      }
      logo {
        localFile {
          publicURL
        }
      }
      background {
        localFile {
          publicURL
        }
      }
    }
  }
`;
